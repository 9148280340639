<template>
  <div>
    <!-- Início filtro -->
    <v-dialog
      v-model="modal.filtro"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-app-bar-nav-icon @click="modal.filtro = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
          <v-icon class="ml-2"> fas fa-building </v-icon>
          <v-toolbar-title class="pl-4"> Filtrar empresas </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="limparFiltros">
            <v-icon>fas fa-broom</v-icon>
          </v-app-bar-nav-icon>
          <v-app-bar-nav-icon
            @click="carregarEmpresas(), (modal.filtro = false)"
          >
            <v-icon>fas fa-search</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>
          <v-col class="pt-6">
            <v-form>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-fingerprint"
                    label="Código"
                    type="number"
                    autocomplete="off"
                    v-model="filtros.codigo"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-passport"
                    label="CNPJ"
                    type="text"
                    autocomplete="off"
                    v-model="filtros.cnpj"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="far fa-id-card"
                    label="Razão social"
                    type="text"
                    autocomplete="off"
                    v-model="filtros.razao_social"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="far fa-id-card"
                    label="Nome fantasia"
                    type="text"
                    autocomplete="off"
                    v-model="filtros.nome_fantasia"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim filtro -->
    <!-- Início detalhes -->
    <v-dialog
      v-model="modal.detalhes"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-icon class="ml-2"> fas fa-boxes </v-icon>
          <v-toolbar-title class="pl-4">
            Detalhes da empresa ({{ empresaAtual }})
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="modal.detalhes = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>

          <v-tabs
            v-model="tab"
            background-color="primary"
            slider-color="red"
            dark
            active-class="active"
          >
            <v-tab v-for="(item, index) in items" :key="item.value" >
              {{ item.tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" >
            <v-tab-item v-for="(item, index) in items" :key="item.value" >
              <v-card flat>
                <v-card-text>
                  <component :cadastro="detalhes" v-bind:is="item.content"></component>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim Detalhes -->
    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon> fas fa-building </v-icon>
        <v-toolbar-title class="pl-4"> Empresas </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="modal.filtro = true">
          <v-icon>fas fa-search</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table
              dense
              :headers="grid.empresas.cabecalho"
              :items="grid.empresas.items"
              :items-per-page="grid.empresas.paginacao"
              hide-default-footer
              class="elevation-1 pt-4"
              :loading="grid.empresas.carregando"
            >
              <template v-slot:item.controls="{ item }">
                <v-app-bar-nav-icon @click="carregarDetalhes(item)">
                  <v-icon>fas fa-search</v-icon>
                </v-app-bar-nav-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim principal -->
  </div>
</template>

<script>
import { baseApiUrl, baseApiUrlPersys } from "@/global";
import axios from "axios";
import Cadastro from "../components/empresa/frmcadastro.vue";
import Correios from "../components/empresa/frmcorreios.vue";

export default {
  name: "Empresas",
  components: {
    Cadastro,
    Correios,
  },
  data: () => ({
    tab: 1,
    items: [
      { tab: 'Cadastro', content: 'Cadastro', value: 1 },
      { tab: 'Correios', content: 'Correios', value: 2 },
      { tab: 'Jad', content: 'Jad', value: 3 },
      { tab: 'AlephCRM', content: 'AlephCRM', value: 4 },
      { tab: 'Proceda', content: 'Proceda', value: 5 },
    ],
    codigo_empresa: '',
    grid: {
      empresas: {
        cabecalho: [
          { text: "#", value: "codigo", sortable: false },
          { text: "CNPJ", value: "cnpj" },
          { text: "Razão social", value: "razao_social" },
          { text: "Nome fantasia", value: "nome_fantasia" },
          { text: "", value: "controls", sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        porPagina: 10,
      },
    },
    modal: {
      filtro: false,
      detalhes: false,
    },
    filtros: {
      codigo: "",
      cnpj: "",
      razao_social: "",
      nome_fantasia: "",
    },
    detalhes: {
      rastreioTokenSisfrete: "",
      rastreioCorreios: false,
      rastreioJad: false,
      rastreioAlephcrm: false,
      rastreioProceda: false,
      emailcorreios: "",
      senhacorreios: "",
      tokenjad: "",
      apikey: "",
      accountid: "",
      show1: false,
      codigo: "",
      razao_social: "",
      fullname: "",
      cnpj: "",
      nome_fantasia: "",
      telefone: "",
      contratocorreios: "",
      cartaocorreios: "",
    },
    empresaAtual: "",
    rastreioTokenSisfrete: true,
    rastreioCorreios: false,
    rastreioJad: false,
    rastreioAlephcrm: false,
    rastreioProceda: false,
    radioProceda: null,
    saveData: false,
    show1: false,
    show2: false,
    factory: "",
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  mounted() {
    this.carregarEmpresas();
    this.carregarTokenSisfrete();
  },
  beforeMount () {
    this.tabItems(1);
  },
  methods: {
    limparFiltros() {
      this.detalhes = {};
    },
    tabItems(tab){
      this.tab = tab.value;
      console.log("tab.value", tab.value);
      console.log("this.detalhes", this.detalhes);
    },
    fechar() {
      this.$router.push("/");
    },
    // async saveToken() {
    //   console.log(this.testeToken);
    //   await axios
    //     .post(`${baseApiUrlPersys}/chaves`, {
    //       codigoEmpresa: this.codigo_empresa,
    //       tokenApi: "teste",
    //     })
    //     .then((res) => {
    //       console.log(res);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    async carregarTokenSisfrete() {
      await axios
        .get(`${baseApiUrlPersys}/chaves/${this.codigo_empresa}`)
        .then((res) => {
          this.detalhes.rastreioTokenSisfrete = res.data.tokenApi;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    carregarEmpresas() {
      this.grid.empresas.carregando = true;
      let url = `${baseApiUrl}/empresas?page=${this.grid.empresas.paginaAtual}`;
      if (
        this.filtros.codigo != "" ||
        this.filtros.cnpj != "" ||
        this.filtros.razao_social != "" ||
        this.filtros.nome_fantasia != ""
      ) {
        url += `&codigo=${this.filtros.codigo}`;
        url += `&cnpj=${this.filtros.cnpj}`;
        url += `&razao_social=${this.filtros.razao_social}`;
        url += `&nome_fantasia=${this.filtros.nome_fantasia}`;
      }
      if (localStorage.getItem("factory"))
        url += `&cod_empresa=${localStorage.getItem("factory")}`;

      axios
        .get(url)
        .then((res) => {
          this.grid.empresas.items = res.data.empresas.data;
          this.grid.empresas.totalRegistros = res.data.empresas.total;
        })
        .finally(() => (this.grid.empresas.carregando = false));
    },
    carregarDetalhes(item) {
      this.empresaAtual = item.razao_social;
      this.modal.detalhes = true;
      this.grid.empresas.carregando = true;
      let url = `${baseApiUrl}/empresas-detalhes?codigo=${item.codigo}&type=detalhes`;

      axios
        .get(url)
        .then((res) => {
          this.detalhes.codigo = res.data.empresas.data[0].codigo;
          this.detalhes.razao_social = res.data.empresas.data[0].razao_social;
          this.detalhes.fullname = this.detalhes.codigo +" - "+ this.detalhes.razao_social;
          this.empresaAtual = this.detalhes.fullname;
          this.detalhes.cnpj = res.data.empresas.data[0].cnpj;
          this.detalhes.nome_fantasia = res.data.empresas.data[0].nome_fantasia;
          this.detalhes.telefone = res.data.empresas.data[0].telefone;
          this.detalhes.contratocorreios = res.data.empresas.data[0].contratocorreios;
          this.detalhes.cartaocorreios = res.data.empresas.data[0].cartaocorreios;
          this.detalhes.rastreioCorreios = res.data.empresas.data[0].rastreioCorreios;
          this.detalhes.emailcorreios = res.data.empresas.data[0].emailcorreios == null ? "" : res.data.empresas.data[0].emailcorreios;
          this.detalhes.senhacorreios = res.data.empresas.data[0].senhacorreios == null ? "" : res.data.empresas.data[0].senhacorreios;
          this.detalhes.rastreioJad = res.data.empresas.data[0].rastreioJad;
          this.detalhes.tokenjad = res.data.empresas.data[0].tokenjad == null ? "" : res.data.empresas.data[0].tokenjad;
          this.detalhes.rastreioAlephcrm = res.data.empresas.data[0].rastreioAlephcrm;
          this.detalhes.apikey = res.data.empresas.data[0].apikey == null ? "" : res.data.empresas.data[0].apikey;
          this.detalhes.accountid = res.data.empresas.data[0].accountid == null ? "" : res.data.empresas.data[0].accountid;

          this.rastreioCorreios = this.detalhes.rastreioCorreios;
          this.rastreioJad = this.detalhes.rastreioJad;
          this.rastreioAlephcrm = this.detalhes.rastreioAlephcrm;
        })
        .catch((error) => {
          // console.log( error )
          this.grid.empresas.carregando = false;
          // showError(error.data.mensagem)
        })
        .finally(() => (this.grid.empresas.carregando = false));
    },
    mudarPagina(pagina) {
      this.grid.empresas.paginaAtual = pagina;
      this.carregarEmpresas();
    },
    saveDetails(form) {
      if (confirm("Gravar os dados alterados?")) {
        // console.log( "form ->", form )
        this.saveData = false;
        this.modal.detalhes = false;
      }
    },
    btnSave() {
      this.saveData = true;
    },
  },
};
</script>

<style scoped>
.btnSalvar {
  color: #fff !important;
}

.active {
  background-color: #018656;
  color: #fff;

  /* background-color: blue;
  color: black */
}
</style>
