<template>
  <v-card>
    <v-card-text>
      <v-col class="pt-6">
        <v-form>
          <v-row>
            <v-col cols="12" md="1">
              <v-checkbox
                class="shrink mr-2 mt-0"
                v-model="cadastro.rastreioCorreios"
                :label="`Rastreio Correios`"
              ></v-checkbox>
            </v-col>
            <v-col v-if="cadastro.rastreioCorreios" cols="12" md="2">
              <v-text-field
                dense
                outlined
                v-model="cadastro.contratocorreios"
                label="Contrato"
                :rules="[
                  () =>
                    (!!cadastro.contratocorreios && cadastro.rastreioCorreios) ||
                    'Campo obrigatório quando rastreamento habilitado.',
                ]"
                type="text"
                autocomplete="off"
                clearable
                @change="btnSave"
              ></v-text-field>
            </v-col>
            <v-col v-if="cadastro.rastreioCorreios" cols="12" md="2">
              <v-text-field
                dense
                outlined
                v-model="cadastro.cartaocorreios"
                label="Cartao"
                :rules="[
                  () =>
                    (!!cadastro.cartaocorreios && cadastro.rastreioCorreios) ||
                    'Campo obrigatório quando rastreamento habilitado.',
                ]"
                type="text"
                autocomplete="off"
                clearable
                @change="btnSave"
              ></v-text-field>
            </v-col>

            <v-col v-if="cadastro.rastreioCorreios" cols="12" md="4">
              <v-text-field
                dense
                outlined
                v-model="cadastro.emailcorreios"
                label="Email"
                :rules="[
                  () =>
                    (!!cadastro.emailcorreios && cadastro.rastreioCorreios) ||
                    'Campo obrigatório quando rastreamento habilitado.',
                ]"
                type="text"
                autocomplete="off"
                clearable
                @change="btnSave"
              ></v-text-field>
            </v-col>
            <v-col v-if="cadastro.rastreioCorreios" cols="12" md="3">
              <v-text-field
                dense
                outlined
                v-model="cadastro.senhacorreios"
                :append-icon="cadastro.show1 ? 'far fa-eye' : 'far fa-eye-slash'"
                :type="cadastro.show1 ? 'text' : 'password'"
                :rules="[
                  () =>
                    (!!cadastro.senhacorreios && cadastro.rastreioCorreios) ||
                    'Campo obrigatório quando rastreamento habilitado.',
                ]"
                label="Senha"
                autocomplete="off"
                @click:append="cadastro.show1 = !cadastro.show1"
                @change="btnSave"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            v-if="saveData == false"
            depressed
            color="#018656"
            class="mt-4 btnSalvar"
          >
            Salvar
          </v-btn>

          <v-btn
            v-if="saveData"
            depressed
            color="#018656"
            class="mt-4 btnSalvar"
            @click="saveDetails(cadastro)"
          >
            Salvar
          </v-btn>
        </v-form>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Correios",
  props: {
    cadastro: { type: Object },
  },
  mounted() {
  },
  beforeMount () {
  }
};
</script>

<style>
</style>