<template>
  <v-card>
    <v-card-text>
      <v-col class="pt-6">
        <v-form>
          <v-row>
            <!-- <v-col cols="12" md="1">
              <v-text-field
                dense
                outlined
                label="Código"
                type="text"
                autocomplete="off"
                v-model="cadastro.codigo"
                :disabled="true"
                clearable
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="5">
              <v-text-field
                dense
                outlined
                label="Cliente"
                type="text"
                autocomplete="off"
                v-model="cadastro.fullname"
                :disabled="true"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                dense
                outlined
                label="CNPJ"
                type="text"
                autocomplete="off"
                v-model="cadastro.cnpj"
                :disabled="true"
                clearable="false"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                dense
                outlined
                label="Nome fantasia"
                type="text"
                autocomplete="off"
                v-model="cadastro.nome_fantasia"
                :disabled="true"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                dense
                outlined
                label="Telefone"
                type="text"
                autocomplete="off"
                v-model="cadastro.telefone"
                :disabled="true"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            v-if="saveData == false"
            depressed
            color="#018656"
            class="mt-4 btnSalvar"
          >
            Salvar
          </v-btn>

          <v-btn
            v-if="saveData"
            depressed
            color="#018656"
            class="mt-4 btnSalvar"
            @click="saveDetails(cadastro)"
          >
            Salvar
          </v-btn>
        </v-form>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Cadastro",
  props: {
    cadastro: { type: Object },
  },
  mounted() {
  },
  beforeMount () {
  }
};
</script>

<style>
</style>